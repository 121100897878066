import * as ACTION_TYPES from './servicesPricesActionTypes';

export const servicesPricesReducerInitialState = {
	servicesPrices: {
		data: [],
    meta:null,
		isFetching: false,
		hasError: false,
		error: {}
	},
	createServicePrice: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: {}
	},

};

export const servicesPricesReducer = (state = servicesPricesReducerInitialState, action) => {
	switch (action.type) {

		//Start Service Prices
		case ACTION_TYPES.GET_SERVICE_PRICES_REQUEST: {

			return {
				...state,
				servicesPrices: {
					...state.servicesPrices,
					isFetching: true,
					hasError: false
				},

			};
		}
		case ACTION_TYPES.GET_SERVICE_PRICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				servicesPrices: {
					...state.servicesPrices,
					data:data?.data,
          meta:data?.meta,
					isFetching: false,
					hasError: false,
					error: servicesPricesReducerInitialState?.servicesPrices?.error,
				},

			};
		}
		case ACTION_TYPES.GET_SERVICE_PRICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				servicesPrices: {
					...state.servicesPrices,
					isFetching: false,
					hasError: true,
					// error,
					data: servicesPricesReducerInitialState?.servicesPrices?.data,
				},

			};
		}
		case ACTION_TYPES.CLEAR_SERVICES_PRICES_REQUEST: {
			return {
				...state,
				servicesPrices: {
					...servicesPricesReducerInitialState?.servicesPrices
				},

			};
		}
		// END  services Prices

		//Start CREATE Service Prices
		case ACTION_TYPES.CREATE_SERVICE_PRICES_REQUEST: {

			return {
				...state,
				createServicePrice: {
					...state.createServicePrice,
					isFetching: true,
					hasError: false
				},

			};
		}
		case ACTION_TYPES.CREATE_SERVICE_PRICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				createServicePrice: {
					...state.createServicePrice,
					data,
					isFetching: false,
					hasError: false,
					error: servicesPricesReducerInitialState?.createServicePrice?.error,
				},

			};
		}
		case ACTION_TYPES.CREATE_SERVICE_PRICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				createServicePrice: {
					...state.createServicePrice,
					isFetching: false,
					hasError: true,
					// error,
					data: servicesPricesReducerInitialState?.createServicePrice?.data,
				},

			};
		}
		case ACTION_TYPES.CLEAR_CREATE_SERVICES_PRICES_REQUEST: {
			return {
				...state,
				createServicePrice: {
					...servicesPricesReducerInitialState?.createServicePrice
				},

			};
		}
		// END CREATE services Prices
		default: {
			return state;
		}
	}
};

export default servicesPricesReducer;

export const getServicesPricesReducer = state => state.servicesPricesReducer;

// Getters services prices
export const getServicesPrices = state => getServicesPricesReducer(state)?.servicesPrices;
export const getServicesPricesData = state => getServicesPrices(state)?.data;
export const getServicesPricesMetaData = state => getServicesPrices(state)?.meta;
export const getServicesPricesIsFetching = state => getServicesPrices(state)?.isFetching;
export const getServicesPricesHasError = state => getServicesPrices(state)?.hasError;
export const getServicesPricesError = state => getServicesPrices(state)?.error;

// CREATE services pricesservicesPrices
export const getCreateServicesPrices = state => getServicesPricesReducer(state)?.createServicePrice;
export const getCreateServicesPricesData = state => getCreateServicesPrices(state)?.data;
export const getCreateServicesPricesIsFetching = state => getCreateServicesPrices(state)?.isFetching;
export const getCreateServicesPricesHasError = state => getCreateServicesPrices(state)?.hasError;
export const getCreateServicesPricesError = state => getCreateServicesPrices(state)?.error;
