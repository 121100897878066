import * as ACTION_TYPES from './servicesActionTypes';

export const servicesReducerInitialState = {
	listServices: {
		data: [],
		meta:null,
		isFetching: false,
		hasError: false,
		error: {}
	},
	findService: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: {}
	},
	createService: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: {}
	},
	updateService: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: {}
	},
	servicesPrices: {
		data: [],
    meta:null,
		isFetching: false,
		hasError: false,
		error: {}
	},

};

export const servicesReducer = (state = servicesReducerInitialState, action) => {
	switch (action.type) {

		// List services filtered action handlers
		case ACTION_TYPES.LIST_SERVICES_REQUEST: {

			return {
				...state,
				listServices: {
					...state.listServices,
					isFetching: true,
					hasError: false
				}

			};
		}

		case ACTION_TYPES.LIST_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listServices: {
					...state.listServices,
					data:data?.data,
					meta:data?.meta,
					isFetching: false,
					hasError: false,
					error: servicesReducerInitialState?.listServices?.error,
				}

			};
		}
		case ACTION_TYPES.LIST_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listServices: {
					...state.listServices,
					isFetching: false,
					hasError: true,
					// error
				},

			};
		}
		case ACTION_TYPES.CLEAR_LIST_SERVICES_REQUEST: {
			return {
				...state,
				listServices: servicesReducerInitialState?.listServices
			};
		}
		// END List services filtered action handlers

		//Start Find Service filter action handles
		case ACTION_TYPES.FIND_SERVICE_REQUEST: {

			return {
				...state,
				findService: {
					...state.findService,
					isFetching: true,
					hasError: false
				},

			};
		}
		case ACTION_TYPES.FIND_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				findService: {
					...state.findService,
					isFetching: false,
					hasError: false,
					error: servicesReducerInitialState?.findService?.error,
					data

				},

			};
		}
		case ACTION_TYPES.FIND_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				findService: {
					...state.findService,
					data: servicesReducerInitialState?.findService?.data,
					isFetching: false,
					hasError: true,
					// error

				},

			};
		}

    case ACTION_TYPES.CLEAR_FIND_SERVICE_REQUEST: {
			return {
				...state,
				findService: servicesReducerInitialState?.findService
			};
		}

		// END Find services filtered action handlers

		//Start CREATE Service filter action handles
		case ACTION_TYPES.CREATE_SERVICE_REQUEST: {

			return {
				...state,
				createService: {
					...state.createService,
					isFetching: true,
					hasError: false
				},

			};
		}
		case ACTION_TYPES.CREATE_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			// state.listServices.data.push(data);
			return {
				...state,
				createService: {
					...state.createService,
					isFetching: false,
					hasError: false,
					error: servicesReducerInitialState?.createService?.error,
					data
				},

			};
		}
		case ACTION_TYPES.CREATE_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				createService: {
					...state.createService,
					isFetching: false,
					hasError: true,
					// error,
					data: servicesReducerInitialState?.createService?.data,
				},

			};
		}

		// END CREATE services filtered action handlers

		//Start UPDATE Service filter action handles
		case ACTION_TYPES.UPDATE_SERVICE_REQUEST: {

			return {
				...state,
				updateService: {
					...state.updateService,
					isFetching: true,
					hasError: false
				},

			};
		}
		case ACTION_TYPES.UPDATE_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			// add to list
			// state.listServices.data.push(data);
			return {
				...state,
				updateService: {
					...state.updateService,
					isFetching: false,
					hasError: false,
					error: servicesReducerInitialState?.updateService?.error,
					data
				},

			};
		}
		case ACTION_TYPES.UPDATE_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				updateService: {
					...state.updateService,
					isFetching: false,
					hasError: true,
					// error,
					data: servicesReducerInitialState?.updateService?.data,
				},

			};
		}

    case ACTION_TYPES.CLEAR_UPDATE_SERVICES_REQUEST: {
			return {
				...state,
				updateService: servicesReducerInitialState?.updateService
			};
		}

		// END CREATE services filtered action handlers
		default: {
			return state;
		}
	}
};

export default servicesReducer;

export const getServicesReducer = state => state.servicesReducer;

// Getters list candidates filtered
export const getListServices = state => getServicesReducer(state)?.listServices;
export const getListServicesData = state => getListServices(state)?.data;
export const getListServicesMetaData = state => getListServices(state)?.meta;
export const getListServicesIsFetching = state => getListServices(state)?.isFetching;
export const getListServicesHasError = state => getListServices(state)?.hasError;
export const getListServicesError = state => getListServices(state)?.error;

//Getters for find  element
export const getFindService = state => getServicesReducer(state)?.findService;
export const getFindServiceData = state => getFindService(state)?.data;
export const getFindServiceIsFetching = state => getFindService(state)?.isFetching;
export const getFindServiceHasError = state => getFindService(state)?.hasError;
export const getFindServiceError = state => getFindService(state)?.error;


// Getters create candidates filtered
export const getCreateServices = state => getServicesReducer(state)?.createService;
export const getCreateServicesData = state => getCreateServices(state)?.data;
export const getCreateServicesIsFetching = state => getCreateServices(state)?.isFetching;
export const getCreateServicesHasError = state => getCreateServices(state)?.hasError;
export const getCreateServicesError = state => getCreateServices(state)?.error;

// Getters update candidates filtered
export const getUpdateServices = state => getServicesReducer(state)?.updateService;
export const getUpdateServicesData = state => getUpdateServices(state)?.data;
export const getUpdateServicesIsFetching = state => getUpdateServices(state)?.isFetching;
export const getUpdateServicesHasError = state => getUpdateServices(state)?.hasError;
export const getUpdateServicesError = state => getUpdateServices(state)?.error;
