
export const USERS_REQUEST = 'USERS_REQUEST'; //para saber si esta buscando (fetching)
export const USERS_REQUEST_SUCCESS = 'USERS_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const USERS_REQUEST_FAILURE = 'USERS_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_USERS_REQUEST = 'CLEAR_USERS_REQUEST'; //con esto limpiamos la data del estado global


//get user by mag
export const USERS_BY_MAG_REQUEST = 'USERS_BY_MAG_REQUEST'; //para saber si esta buscando (fetching)
export const USERS_BY_MAG_REQUEST_SUCCESS = 'USERS_BY_MAG_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const USERS_BY_MAG_REQUEST_EXISTS_SUCCESS = 'USERS_BY_MAG_REQUEST_EXISTS_SUCCESS'; //Si el usuario existe en el SSO
export const USERS_BY_MAG_REQUEST_FAILURE = 'USERS_BY_MAG_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_USERS_BY_MAG_REQUEST = 'CLEAR_USERS_BY_MAG_REQUEST'; //con esto limpiamos la data del estado global



export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_REQUEST_SUCCESS = 'DELETE_USERS_REQUEST_SUCCESS';
export const DELETE_USERS_REQUEST_FAILURE = 'DELETE_USERS_REQUEST_FAILURE';


export const POST_USERS_REQUEST = 'POST_USERS_REQUEST';
export const POST_USERS_REQUEST_SUCCESS = 'POST_USERS_REQUESTT_SUCCESS';
export const POST_USERS_REQUEST_FAILURE = 'POST_USERS_REQUEST_FAILURE';
export const CLEAR_POST_USERS_BY_MAG_REQUEST = 'CLEAR_POST_USERS_BY_MAG_REQUEST'; //con esto limpiamos la data del estado global

export const RESTORE_USER_REQUEST = 'RESTORE_USER_REQUEST';
export const RESTORE_USER_REQUEST_SUCCESS = 'RESTORE_USER_REQUEST_SUCCESS';
export const RESTORE_USER_REQUEST_FAILURE = 'RESTORE_USER_REQUEST_FAILURE';

export const PUT_CHANGE_PASSWORD_REQUEST = 'PUT_CHANGE_PASSWORD_REQUEST';
export const PUT_CHANGE_PASSWORD_REQUEST_SUCCESS = 'PUT_CHANGE_PASSWORD_REQUEST_SUCCESS';
export const PUT_CHANGE_PASSWORD_REQUEST_FAILURE = 'PUT_CHANGE_PASSWORD_REQUEST_FAILURE';
