import * as ACTION_TYPES from './globalDataActionTypes';

export const globalDataReducerInitialState = {
	sidebarShow: true,
};

export const globalDataReducer = (state = globalDataReducerInitialState, action) => {
	switch (action.type) {

		// sidebar Show
		case ACTION_TYPES.SET_SIDEBAR_SHOW: {
			const { data } = action.payload;
			return {
				...state,
				sidebarShow: data
			};
		}
		/* case ACTION_TYPES.GET_SIDEBAR_SHOW: {
			return {
				...state,
				
			};
		} */
		default: {
			return state;
		}
	}
};

export const getGlobalDataReducer = state => state.globalDataReducer;

export const getSideBarShow = state => getGlobalDataReducer(state)?.sidebarShow;
