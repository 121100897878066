
//only GET SERVICES PRICES
export const GET_SERVICE_PRICES_REQUEST = 'GET_SERVICE_PRICES_REQUEST';
export const GET_SERVICE_PRICES_REQUEST_SUCCESS = 'GET_SERVICE_PRICES_REQUEST_SUCCESS';
export const GET_SERVICE_PRICES_REQUEST_FAILURE = 'GET_SERVICE_PRICES_REQUEST_FAILURE';
export const CLEAR_SERVICES_PRICES_REQUEST = 'CLEAR_SERVICES_PRICES_REQUEST'; //con esto limpiamos la data del estado global

//only CREATE SERVICES PRICES
export const CREATE_SERVICE_PRICES_REQUEST = 'CREATE_SERVICE_PRICES_REQUEST';
export const CREATE_SERVICE_PRICES_REQUEST_SUCCESS = 'CREATE_SERVICE_PRICES_REQUEST_SUCCESS';
export const CREATE_SERVICE_PRICES_REQUEST_FAILURE = 'CREATE_SERVICE_PRICES_REQUEST_FAILURE';
export const CLEAR_CREATE_SERVICES_PRICES_REQUEST = 'CLEAR_CREATE_SERVICES_PRICES_REQUEST'; //con esto limpiamos la data del estado global

