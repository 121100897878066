import * as ACTION_TYPES from './paymentActionTypes';

export const paymentReducerInitialState = {
	getPayments:{
		hasError: false,
		isFetching: false,
		error: {},
		data:[],
		meta:null
	}
};

export const paymentReducer = (state = paymentReducerInitialState, action) => {
	switch (action.type) {
		//START payments
		case ACTION_TYPES.GET_PAYMENT_REQUEST: {
			return {
				...state,
				getPayments:{
					...state.getPayments,
					isFetching: true,
					hasError: false,
					error: undefined,
				}
			};
		}
		case ACTION_TYPES.GET_PAYMENT_REQUEST_SUCCESS: {
			const { data,meta} = action.payload;
			return {
				...state,
				getPayments:{
					...state.getPayments,
					isFetching: false,
					hasError: false,
					error: undefined,
					data:data,
					meta:meta,
				}
			};
		}
		case ACTION_TYPES.GET_PAYMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getPayments:{
					...state.getPayments,
					isFetching: false,
					hasError: true,
					// error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_PAYMENT_REQUEST: {
			return {
				...paymentReducerInitialState
			};
		}
		//END Payment Helipago actions


		default: {
			return state;
		}
	}
};

export default paymentReducer;



export const getPaymentReducer = state => state.paymentReducer;

export const getListPaymentsData = state => getPaymentReducer(state)?.getPayments?.data;
export const getListPaymentsDataMetaData = state => getPaymentReducer(state)?.getPayments?.meta;
export const getListPaymentsIsFetching = state => getPaymentReducer(state)?.getPayments?.isFetching;
