import * as ACTION_TYPES from './statisticsActionTypes'

export const defaultValues = {
  turns: {
    assigned: 0,
    available: 18,
  },
  amounts: {
    online: 0,
    plant: 0,
  },
}
export const statisticsReducerInitialState = {
  getStatistics: {
    data: {
      statistics_per_day: defaultValues,
      statistics_per_month: defaultValues,
      statistics_per_year: defaultValues,
      onlineVsPlant: [],
    },
    meta: null,
    isFetching: false,
    hasError: false,
    error: {},
  },
}

export const statisticsReducer = (state = statisticsReducerInitialState, action) => {
  switch (action.type) {
    // List plants filtered action handlers
    case ACTION_TYPES.STATISTICS_REQUEST: {
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.STATISTICS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          isFetching: false,
          hasError: false,
          error: statisticsReducerInitialState?.getStatistics?.error,
          data: { ...state?.getStatistics?.data, ...data?.data },
        },
      }
    }

    case ACTION_TYPES.STATISTICS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          isFetching: false,
          hasError: true,
          data: statisticsReducerInitialState?.getStatistics?.data,
          // error,
        },
      }
    }
    case ACTION_TYPES.STATISTICS_ONLINE_VS_PLANT_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          isFetching: false,
          hasError: false,
          error: statisticsReducerInitialState?.getStatistics?.error,
          data: { ...state?.getStatistics?.data, onlineVsPlant: data?.data },
        },
      }
    }
    case ACTION_TYPES.STATISTICS_ONLINE_VS_PLANT_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          isFetching: false,
          hasError: true,
          data: statisticsReducerInitialState?.getStatistics?.data,
          // error,
        },
      }
    }

    case ACTION_TYPES.CLEAR_STATISTICS_REQUEST: {
      return {
        ...state,
        getStatistics: statisticsReducerInitialState?.getStatistics,
      }
    }
    // END List plants filtered action handlers

    default: {
      return state
    }
  }
}

export default statisticsReducer

export const getStatisticsReducer = (state) => state.statisticsReducer

// Getters list candidates filtered
export const getStatistics = (state) => getStatisticsReducer(state)?.getStatistics
export const getStatisticsData = (state) => getStatistics(state)?.data
export const getStatisticsMetaData = (state) => getStatistics(state)?.meta
export const getStatisticsIsFetching = (state) => getStatistics(state)?.isFetching
export const getStatisticsHasError = (state) => getStatistics(state)?.hasError
export const getStatisticsError = (state) => getStatistics(state)?.error
