import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import configureStore, { history } from 'src/redux/store';

const store = configureStore();

const render = () => {
	ReactDOM.render(
		<>
			<Provider store={store}>
				<App history={history} />
			</Provider>
		</>,
		document.getElementById('root')
	)
}

render()

reportWebVitals()
