
export const SERVICES_REQUEST = 'SERVICES_REQUEST'; //para saber si esta buscando (fetching)
export const SERVICES_REQUEST_SUCCESS = 'SERVICES_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const SERVICES_REQUEST_FAILURE = 'SERVICES_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500

//list elements
export const LIST_SERVICES_REQUEST = 'SERVICES_REQUEST'; //para saber si esta buscando (fetching)
export const LIST_SERVICES_REQUEST_SUCCESS = 'SERVICES_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const LIST_SERVICES_REQUEST_FAILURE = 'SERVICES_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_LIST_SERVICES_REQUEST = 'CLEAR_SERVICES_REQUEST'; //con esto limpiamos la data del estado global

//only find an element
export const FIND_SERVICE_REQUEST = 'FIND_SERVICE_REQUEST';
export const FIND_SERVICE_REQUEST_SUCCESS = 'FIND_SERVICE_REQUEST_SUCCESS';
export const FIND_SERVICE_REQUEST_FAILURE = 'FIND_SERVICE_REQUEST_FAILURE';
export const CLEAR_FIND_SERVICE_REQUEST = 'CLEAR_FIND_SERVICE_REQUEST';

//only create element
export const CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST';
export const CREATE_SERVICE_REQUEST_SUCCESS = 'CREATE_SERVICE_REQUEST_SUCCESS';
export const CREATE_SERVICE_REQUEST_FAILURE = 'CREATE_SERVICE_REQUEST_FAILURE';
export const CLEAR_CREATE_SERVICES_REQUEST = 'CLEAR_CREATE_SERVICES_REQUEST'; //con esto limpiamos la data del estado global

//only create element
export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_REQUEST_SUCCESS = 'UPDATE_SERVICE_REQUEST_SUCCESS';
export const UPDATE_SERVICE_REQUEST_FAILURE = 'UPDATE_SERVICE_REQUEST_FAILURE';
export const CLEAR_UPDATE_SERVICES_REQUEST = 'CLEAR_UPDATE_SERVICES_REQUEST'; //con esto limpiamos la data del estado global
