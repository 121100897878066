import * as ACTION_TYPES from './coreApiActionTypes';

export const coreApiReducerInitialState = {
	getDependencies: {
		data: [],
		isFetching: false,
		hasError: false,
		error: {}
	}
};

export const coreApiReducer = (state = coreApiReducerInitialState, action) => {
	switch (action.type) {

		// List users filtered action handlers
		case ACTION_TYPES.GET_DEPENDENCIES_REQUEST: {
			return {
				...state,
				getDependencies: {
					...state.getDependencies,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_DEPENDENCIES_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				getDependencies: {
					...state.getDependencies,
					isFetching: false,
					hasError: false,
					error: coreApiReducerInitialState?.getDependencies?.error,
					data
				}
			};

		}
		case ACTION_TYPES.GET_DEPENDENCIES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getDependencies: {
					...state.getDependencies,
					isFetching: false,
					hasError: true,
					data: coreApiReducerInitialState?.getDependencies?.data,
					// error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_DEPENDENCIES_REQUEST: {
			return {
				...state,
				getDependencies: coreApiReducerInitialState?.getDependencies
			};
		}
		// END List users filtered action handlers

		default: {
			return state;
		}
	}
};

export default coreApiReducer;

export const getCoreApiReducer = state => state.coreApiReducer;

// Getters list candidates filtered
export const getDependencies = state => getCoreApiReducer(state)?.getDependencies;
export const getDependenciesData = state => getDependencies(state)?.data;
export const getDependenciesIsFetching = state => getDependencies(state)?.isFetching;
export const getDependenciesHasError = state => getDependencies(state)?.hasError;
export const getDependenciesError = state => getDependencies(state)?.error;


