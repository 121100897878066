
export const PLANTS_REQUEST = 'PLANTS_REQUEST'; //para saber si esta buscando (fetching)
export const PLANTS_REQUEST_SUCCESS = 'PLANTS_REQUEST_SUCCESS'; //si los datos llegan correcto, o sea en un 200 201 o 204
export const PLANTS_REQUEST_FAILURE = 'PLANTS_REQUEST_FAILURE'; //si falla el request y devuelve error, ej: 400, 404, 500
export const CLEAR_PLANTS_REQUEST = 'CLEAR_PLANTS_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const FIND_PLANT_REQUEST = 'FIND_PLANT_REQUEST';
export const FIND_PLANT_REQUEST_SUCCESS = 'FIND_PLANT_REQUEST_SUCCESS';
export const FIND_PLANT_REQUEST_FAILURE = 'FIND_PLANT_REQUEST_FAILURE';
export const CLEAR_FIND_PLANTS_REQUEST = 'CLEAR_FIND_PLANTS_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const UPDATE_PLANT_REQUEST = 'UPDATE_PLANT_REQUEST';
export const UPDATE_PLANT_REQUEST_SUCCESS = 'UPDATE_PLANT_REQUEST_SUCCESS';
export const UPDATE_PLANT_REQUEST_FAILURE = 'UPDATE_PLANT_REQUEST_FAILURE';
export const CLEAR_UPDATE_PLANTS_REQUEST = 'CLEAR_UPDATE_PLANTS_REQUEST'; //con esto limpiamos la data del estado global

//only an element
export const CREATE_PLANT_REQUEST = 'CREATE_PLANT_REQUEST';
export const CREATE_PLANT_REQUEST_SUCCESS = 'CREATE_PLANT_REQUEST_SUCCESS';
export const CREATE_PLANT_REQUEST_FAILURE = 'CREATE_PLANT_REQUEST_FAILURE';
